import * as React from "react"
import Head from "next/head"
import type { AppProps } from "next/app"
import Script from "next/script"
import "react-calendar/dist/Calendar.css"
import "rc-slider/assets/index.css"
import "react-day-picker/dist/style.css"
import "glider-js/glider.min.css"
import "../src/styles/index.scss"
import "../src/styles-branded/index.scss"
import "../src/styles/button/buttons.scss"
import "../src/core/components/SocialAvatars/social-avatars.scss"
import "../src/core/components/BrowserWarningBanner/browser-warning-banner.scss"
import "../src/core/components/UserAvatar/styles.scss"
import "../src/core/components/Header/components/MobileNavigation/mobile-navigation.scss"
import "../src/core/components/Header/components/DesktopNavigation/desktop-navigation.scss"
import "../src/core/components/Header/header-user-picture.scss"
import "../src/core/components/Header/page-header.scss"
import "../src/core/components/PsychologistAvatar/psychologist-avatar.scss"
import "../src/core/components/SupportDescription/SupportDescription.scss"
import "../src/core/components/Price/price-and-discount.scss"
import "../src/core/components/Tabs/tabs.scss"
import "../src/core/components/BulletList/bullet-list.scss"
import "../src/core/components/Footer/footer.scss"
import "../src/core/components/ProfileCard/style.scss"
import "../src/core/components/CertificateBlock/CertificateBlock.scss"
import "../src/core/components/CollapseIcon/collapse-icon.scss"
import "../src/core/components/NotificationBanner/notification-banner.scss"
import "../src/core/components/PhoneInput/styles.scss"
import "../src/core/components/CourseBanner/style.scss"
import "../src/core/components/RecommendToEmployerBanner/style.scss"
import "../src/core/components/FAQ/faq.scss"
import "../src/core/components/Spinner/spinner.scss"
import "../src/core/components/DownloadLinks/style.scss"
import "../src/core/components/VideoCallLocation/style.scss"
import "../src/core/components/ClubBanner/styls.scss"
import "../src/core/components/AdviceBlock/style.scss"
import "../src/styles/profile.scss"
import "../src/styles/search.scss"
import "../src/styles/strike.scss"
import "../src/styles/booking.scss"
import "../src/styles/google_signin.scss"
import "../src/styles/psy-account.scss"
import "../src/PsychologistAccount/components/Booking/index.scss"
import "../src/PsychologistAccount/PaymentsReportsTab/date-picker.scss"
import "../src/PsychologistAccount/SettingsTab/styles.scss"
import "../src/PsychologistAccount/SettingsTab/components/PsyIndexQuality/styles.scss"
import "../src/PsychologistAccount/SettingsTab/components/PsyIndexQuality/components/PieChart/styles.scss"
import "../src/PsychologistAccount/SettingsTab/components/TelegramAccount/AttachTelegram/styles.scss"
import "../src/PsychologistAccount/Header/styles.scss"
import "../src/PsychologistAccount/HeaderQualityLabel/styles.scss"
import "../src/PsychologistAccount/BookingDetails/style.scss"
import "../src/PsychologistAccount/ClientsFeedbackTab/style.scss"
import "../src/SelectionOfPsychologists/survey-results.scss"
import "../src/Booking/index.scss"
import "../src/BookingCommon/style.scss"
import "../src/discount/header-discount-badge.scss"
import "../src/discount/discount.scss"
import "../src/NotFoundPage/not-found-page.scss"
import "../src/PsyProfile/components/AudioPlayer/audio-player.scss"
import "../src/PsyProfile/components/BookingPanel/styles.scss"
import "../src/ClientAuthorization/client-authorization.scss"
import "../src/HRAccount/HR-account.scss"
import "../src/PersonalInfoText/personal-info-text.scss"
import "../src/FreePsysPage/styles.scss"
import "../src/Stripe/styles.scss"
import "../src/PsyProfile/components/AlterPlusBadge/alter-plus-badge.scss"
import "../src/PsyProfile/components/AlterPlusInvertedBadge/alter-plus-inverted-badge.scss"
import "../src/PsyProfile/components/AlterPlusLogo/alter-plus-logo.scss"
import "../src/PsyProfile/components/AlterPlus/alter-plus.scss"
import "../src/PsyProfile/components/SessionCountLabel/session-count-label.scss"
import "../src/PsyProfile/components/VideoYoutubePlayer/video-youtube-player.scss"
import "../src/PsyCatalog/common/PromotedPsys/style.scss"
import "../src/PsyProfile/components/MainInfo/main-info.scss"
import "../src/PsyProfile/components/TherapyRequestLookups/styles.scss"
import "../src/Call/components/styles.scss"
import "../src/Call/components/GoToCallButton/style.scss"
import "../src/Call/components/PageError/style.scss"
import "../src/ClientAccount/index.scss"

import { INCLUDE_PROMO_CODE_BANNER_JS, PROMO_CODE_BANNER_SCRIPT_URL } from "../src/env-management"

function CustomApp({ Component, pageProps }: AppProps) {
    return (
        <>
            <Head>
                <title>Подбор психолога - Alter</title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
            </Head>
            {INCLUDE_PROMO_CODE_BANNER_JS && (
                <Script
                    src={PROMO_CODE_BANNER_SCRIPT_URL}
                    onLoad={() => window.showPromoCodeBanner?.("app")}
                />
            )}
            <Component {...pageProps} />
        </>
    )
}

export default CustomApp
